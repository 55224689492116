import { countBy, filter, includes, sum } from 'lodash'

export default class FpdAdapter {

  static TEXT_TYPES = ['i-text', 'text', 'textbox', 'curved-text']
  static IMAGE_TYPES = ['image']

  constructor(instance) {
    this.fpd = instance
  }

  getCustomTextCount() {
    let customElements = this.fpd.getCustomElements()
    let counts = countBy(customElements, (entry) => {
      return includes(FpdAdapter.TEXT_TYPES, entry.element.type)
    })

    return counts[true] || 0
  }

  getCustomImageCount() {
    let customElements = this.fpd.getCustomElements()
    let counts = countBy(customElements, (entry) => {
      // images are non text elements
      // TODO find clear definition
      return includes(FpdAdapter.TEXT_TYPES, entry.element.type)
    })

    return counts[false] || 0
  }

  getDynamicDesignModules() {
    return this.fpd.mainOptions.dynamicDesigns
  }

  translateArea(node) {
    return this.fpd.translator.translateArea(node)
  }
}
