import FPDManager from './fpd-manager.js'
import $ from 'jquery'

import { isString } from 'lodash'

export default class ExternalElementsOperator {
  constructor(productPage) {
    this.productPage = productPage
    this.fpdManager = productPage.fpdManager
    this.wrappedModules = []
  }

  get taEE() {
    return {
      moduleWrapper: '.fpd-sc-module-wrapper',
      actionPlaceholder: '.fpd-sc-action-placeholder'
    }
  }

  /**
   * Waiting vor fpd:ui-set and fpd:ready and initializing the external
   * elements.
   *
   */
  initListener() {
    this.fpdManager.addDesignerReadyCallback(this.initExternalModules.bind(this))
    this.fpdManager.addDesignerReadyCallback(this.initDynamicDesignModules.bind(this))
  }

  initExternalModules() {
    const that = this
    $(that.taEE.moduleWrapper).each((_index, moduleWrapper) => {
      const $moduleWrapper = $(moduleWrapper)
      const moduleType = $moduleWrapper.data('type')
      let additionalElementOptions = $moduleWrapper.data('other') || {}
      const moduleInstance = that.fpdManager.createModule(moduleType, moduleWrapper, additionalElementOptions)

      if(moduleInstance) {
        $moduleWrapper.addClass('fpd-container fpd-external-module')
        that.fpdManager.wrappedInstance.translateArea(moduleWrapper)
        that.wrappedModules.push(moduleInstance)
      }
    })
  }

  initDynamicDesignModules() {
    const that = this
    $(`${that.taEE.moduleWrapper}[data-type^='designs_']`).each((_index, moduleWrapper) => {
      const $moduleWrapper = $(moduleWrapper)

      const dynamicDesignId = $moduleWrapper.data('type').split('_').pop()
      const dynamicDesignsModules = that.fpdManager.wrappedInstance.getDynamicDesignModules()

      if(dynamicDesignId && dynamicDesignsModules[dynamicDesignId]) {
        $moduleWrapper.addClass('fpd-container fpd-external-module')
        console.debug(`Loaidng Dynamic Design Module ${dynamicDesignId}.`)
        const moduleInstance = that.fpdManager.createModule('designs', moduleWrapper, dynamicDesignId)
        that.fpdManager.wrappedInstance.translateArea(moduleWrapper)
      } else {
        console.debug(`Dynamic Design Module ${dynamicDesignId} not defined.`)
      }
    })
  }
}
