import FPDManager from './services/fpd-manager.js'
import initStorefront from './processes/init-storefront.js'
import jQuery from 'jquery'

import FancyProductDesigner from './lib/classes/FancyProductDesigner.js'
import FancyProductDesignerView from './lib/classes/FancyProductDesignerView.js'

import FPDProductsModule from './lib/ui/controller/modules/Products.js'
import FPDTextModule from './lib/ui/controller/modules/Text.js'
import FPDDesignsModule from './lib/ui/controller/modules/Designs.js'
import FPDImagesModule from './lib/ui/controller/modules/Images.js'
import FPDLayoutsModule from './lib/ui/controller/modules/Layouts.js'
import FPDTextLayersModule from './lib/ui/controller/modules/TextLayers.js'
import FPDLayersModule from './lib/ui/controller/modules/Layers.js'
import FPDNamesNumbersModule from './lib/ui/controller/modules/NamesNumbers.js'
import FPDUploadsModule from './lib/ui/controller/modules/Uploads.js'
import FPDUploadZoneModule from './lib/ui/controller/modules/UploadZone.js'
import FPD3DPreview from './lib/ui/controller/addons/FPD3DPreview.js'
import * as FPDUtil from './lib/helpers/utils.js'

FPDManager.addMappedClass('FPDProductsModule', FPDProductsModule)
FPDManager.addMappedClass('FPDTextModule', FPDTextModule)
FPDManager.addMappedClass('FPDDesignsModule', FPDDesignsModule)
FPDManager.addMappedClass('FPDImagesModule', FPDImagesModule)
FPDManager.addMappedClass('FPDLayoutsModule', FPDLayoutsModule)
FPDManager.addMappedClass('FPDDrawingModule', {})
FPDManager.addMappedClass('FPDTextLayersModule', FPDTextLayersModule)
FPDManager.addMappedClass('FPDLayersModule', FPDLayersModule)
FPDManager.addMappedClass('FPDNamesNumbersModule', FPDNamesNumbersModule)
FPDManager.addMappedClass('FPDUploadsModule', FPDUploadsModule)
FPDManager.addMappedClass('FPDUploadZoneModule', FPDUploadZoneModule)
FPDManager.addMappedClass('FPDDynamicViews', {})

FPDManager.addMappedClass('FPDUtil', FPDUtil)
FPDManager.addMappedClass('FancyProductDesigner', FancyProductDesigner)
FPDManager.addMappedClass('FancyProductDesignerView', FancyProductDesignerView)
FPDManager.addMappedClass('FPD3DPreview', FPD3DPreview)

if(typeof FPD !== 'undefined') {
 initStorefront()
}

export { jQuery }







