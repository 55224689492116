import LanguageModel from '../models/language-model.js'
import CartModel from '../models/cart-model.js'

import { isNil, map, findKey, includes, get } from 'lodash'
import $ from 'jquery'

export default class CartPage {
  static urlRegex = /\/cart.*/
  static isUrl(url = window.location.href) {
    const pathname = new URL(url).pathname
    return CartPage.urlRegex.test(pathname)
  }

  constructor(optionsManager) {
    this.optionsManager = optionsManager
    this.taCart = optionsManager.ta.cart
    this.cartModel = CartModel
  }

  initPage() {
    this.initLanguageModal()
    this.updateImages()
    this.updateEditLinks()
    this.initListeners()
    this.executeCallback()
  }

  initListeners() {
    this.initBeforeCheckoutListen()
  }

  executeCallback() {
    if(this.optionsManager.hasCallback('cartAfterUpdate')) {
      this.optionsManager.invokeCallback('cartAfterUpdate')
    }
  }

  initLanguageModal() {
    const languageUrl = LanguageModel.getLanguageUrl(this.optionsManager)
    return fetch(languageUrl)
      .then(response => response.json())
      .then(data => {
        LanguageModel.init(data.shopify)
      })
  }

  initBeforeCheckoutListen() {
    if(this.optionsManager.hasCallback('cartBeforeCheckout')) {
      $("#checkout").on('click', (e) => {
        this.optionsManager.invokeCallback('cartBeforeCheckout')
      })
    }
  }

  getCheckoutForm() {
    return $(this.taCart.checkoutForm)
  }

  updateImages() {
    let results, itemSelector, $item, imageSelector, $selectedImages, $image, $imageOrg
    itemSelector = this.taCart.checkoutFormItem
    imageSelector = this.taCart.itemImage

    return this.cartModel.getCurrentCart().then((cartData) => {
      results = map(cartData.items, (item, index) => {
        let resultValue = null
        const imageUrlKey = findKey(item.properties, (v, k) => includes(k, 'fpd-url'))
        if(isNil(imageUrlKey)) return 'non-fpd' // continue

        const imageUrl = item.properties[imageUrlKey]
        $item = $(this.getCheckoutForm().find(itemSelector)[index])
        $selectedImages = $($item.find(imageSelector))
        $imageOrg = $selectedImages.clone()

        if($selectedImages.is('img')) {
          if($selectedImages.length > 1) {
            $image = $($selectedImages.find('img'))
          } else {
            $image = $selectedImages
          }
          
          $image.attr('src', imageUrl)
          $image.attr('srcset', imageUrl)
          resultValue = 'replaced'
        }
        if($selectedImages.is('a')) {
          if($selectedImages.length > 1) {
            $image = $($selectedImages.find('a'))
          } else {
            $image = $selectedImages
          }

          $image.attr('href', imageUrl)
          if($image.css('background-image')) {
            $image.css('background-image', `url(${imageUrl})`)
          }
          resultValue = 'replaced'
        }
        if(resultValue === null && $selectedImages.length > 0) {
          $image = $($selectedImages[0])
          $image.append(`<img src='${imageUrl}' srcset='${imageUrl}' />`)
          resultValue = 'appended'
        }
        resultValue ||= 'not-found'

        if(this.optionsManager.hasCallback('cartImageFallback')) {
          resultValue = this.optionsManager.invokeCallback('cartImageFallback', item, $item, $selectedImages, $imageOrg)
        }
        return resultValue
      })

      return results
    })
  }

  updateEditLinks() {
    let results, $item
    let itemSelector = this.taCart.checkoutFormItem
    let itemAttachSelector = this.taCart.itemAttach || '.product-option:last-child'

    return this.cartModel.getCurrentCart().then(cartData => {
      results = map(cartData.items, (item, index) => {
        const itemHashKey = findKey(item.properties, (v, k) => includes(k, 'fpd-hash'))
        if(isNil(itemHashKey)) return 'non-fpd' // continue

        $item = $(this.getCheckoutForm().find(itemSelector)[index])

        let editUrl = this.getEditUrl(item)
        // console.debug(`Item: ${item.title} (${index}) - ${editUrl}`)

        let $productLinks = $item.find(`*[href*='${item.url}']`)
        map($productLinks, ($productLink) => {
          $productLink = $($productLink)
          $productLink.attr('href', editUrl)
        })

        if(this.optionsManager.isAttachEditLink()) {
          const $editLink = $(`<a href='${editUrl}' class='fpd-edit-button'>${LanguageModel.lookup('editLinkText', 'Edit')}</a>`)
          $($item.find(itemAttachSelector)).append($editLink)
        }
        return 'replaced'
      })
      return results
    })
  }

  getEditUrl(item) {
    const fpdHash = get(item, "properties['_fpd-hash']")
    const productHandle = get(item, "properties['_fpd-handle']")
    let editUrl = null

    if(!isNil(productHandle)) {
      editUrl = `${window.location.origin}/products/${productHandle}?variant=${item.variant_id}`
    } else {
      editUrl = item.url
    }

    if(!isNil(fpdHash)) {
      editUrl += (editUrl.includes("?") ? '&' : '?')
      editUrl += `_fpd-hash=${fpdHash}`
      editUrl += `&li-key=${item.key}`
    }

    return editUrl
  }
}
