import { get, set, includes, each } from 'lodash'
import $ from 'jquery'

export default class DesignerModeOperator {
  constructor(productPage) {
    this.productPage = productPage
  }

  getDesignerMode() {
    return get(FPD, 'ta.config.designerMode')
  }
  setDesignerMode(newMode) {
    return set(FPD, 'ta.config.designerMode', newMode)
  }
  isCustomize() {
    return this.getDesignerMode() == 'customize'
  }

  /**
   * Find or creates an fpdNode by the selector in FPD.ta.product.fpdContainer.
   * If multiple are found the the first is used.
   * If selection does not contain #fpd selector inner div with id fpd is added.
   * Adds the container_classes from product.
   *
   * Access:
   * - optionsManager
   *
   * SideEffects:
   * - maybe update the ta.product.fpdContainer
   *
   * @return {jQuery}       the node in the dom
   */
  fetchFpdNode() {
    const SAVE_HTML_TAGS = ["DIV","SPAN","P","ARTICLE","MAIN","H1","H2","H3","H4",
                            "H5","H6","SECTION","HEADER"]
    const fpdContainer = this.productPage.taProduct.fpdContainer
    let $fpdNode = $(fpdContainer)

    if($fpdNode.length == 0) {
      throw new Error("No fpd Node found.")
    }

    if($fpdNode.length > 1) {
      $fpdNode = $($fpdNode[0])
      console.debug(`Multiple fpd nodes found for: ${fpdContainer}. Using first.`, $fpdNode)
    }

    if(!includes(SAVE_HTML_TAGS, $fpdNode.prop("tagName"))) {
      console.debug(`FPD Node not using a save tag '${$fpdNode.prop("tagName")}'. This can create issues. Use a save html tag like: ${SAVE_HTML_TAGS}.`)
    }

    if(!FPD.ta.product.fpdContainer.includes("#fpd")) {
      console.debug("Adding inner div#fpd to avoid problems.")
      $fpdNode.html("<div id='fpd'><div>")


      const newFpdContainer = `${fpdContainer} div#fpd`
      this.productPage.updateTa('product.fpdContainer', newFpdContainer)

      $fpdNode = $(newFpdContainer)
    }

    $fpdNode.removeClass().addClass(this.productPage.fpdProductJSON.container_classes)

    return $fpdNode
  }

  /**
   * Creates an fpdNode with the given id and assigns the id
   * to the `ta.product.fpdContainer` Adds the container_classes from product.
   *
   * Side-Effects:
   * - update `ta.product.fpdContainer`
   * - Also removes the class `fpd-overflow-hidde` from body. (unclear)
   *
   * @param  {String} fpdId Id the of the node default 'fpd'
   * @return {jQuery}       the node in the dom
   */
  createFpdNode(fpdId = 'fpd') {
    const fpdSelector = `#${fpdId}`
    let $fpdNode = $(`<div id='${fpdId}'></div>`)
    this.productPage.updateTa('product.fpdContainer', fpdSelector)

    $("body").removeClass("fpd-overflow-hidden")
    $("body").append($fpdNode)

    $fpdNode.removeClass().addClass(this.productPage.fpdProductJSON.container_classes)

    return $fpdNode
  }

  /**
   * Decides how the designer should be shown
   * - Modes are legacy, page, customize, advanced
   * Side Effects:
   *      - Change FPD.ta.config.designerMode if legacy.
   *      - FPD.$fpdNode is assigned to container
   *      - FPD.$customizeButton maybe assigned in some modes
   *      - FPD.$addToCartButtons is assigned => move to verify setup?
   */
  prepare() {

    // let $fpdContainerSelection = $(FPD.ta.product.fpdContainer)
    // let $fpdCustomizeButtonSelection = $(FPD.ta.modal.customizeButton)

    const $fpdContainerSelection = this.productPage.$fpdContainer
    const $fpdCustomizeButtonSelection = this.productPage.$customizeButton
    const fpdContainer = this.productPage.taProduct.fpdContainer
    const customizeButton = this.productPage.taModal.customizeButton

    switch(this.getDesignerMode()) {
      case "legacy":
        /*
          Legecy means nothing was selected.
          If FPD container and button present use advanced mode,
          if only fpd container present use page mode,
          otherwise use customize button mode.
         */
        console.debug("designerMode legacy")
        if($fpdContainerSelection.length > 0
           && $fpdCustomizeButtonSelection.length > 0) {
          this.setDesignerMode("advanced")
        } else if($fpdContainerSelection.length > 0) {
          this.setDesignerMode("page")
        } else {
          this.setDesignerMode("customize")
        }
        //Recall with new value
        this.prepare()
        break;
      case "page":
        /*
          Use the container on the page.
          if no container error
         */
        console.debug("designerMode page")
        if($fpdContainerSelection.length > 0) {
          this.productPage.$fpdNode = this.fetchFpdNode()
          console.debug(`Found FPD node for ${fpdContainer}`);
        } else {
          console.debug(`No node found for ${fpdContainer}`);
          console.error("FancyProductDesigner in page mode, but no selector found.")
        }
        break;
      case "customize":
        /*
          Use the customize button if present.
          Only use the **#fpd** container if present.
          Add #fpd if not present.
          add button is not present. (aka fallback)
         */
        console.debug("designerMode customize")
        if(fpdContainer == "#fpd" && $fpdContainerSelection.length > 0) {
          this.productPage.$fpdNode = this.fetchFpdNode()
          console.debug(`Found FPD node for ${fpdContainer}`);
        } else {
          console.debug(`No node found for ${fpdContainer}. Creating...`);
          this.productPage.$fpdNode = this.createFpdNode()
        }

        if($fpdCustomizeButtonSelection.length > 0) {
          console.debug(`Found customize button for ${customizeButton}`);
        } else {
          console.debug(`No customize button for ${customizeButton}. Creating...`);
          this.productPage.$customizeButton = this.productPage.cbOperator.createButton()
        }

        this.productPage.updateFancyPluginOptions('modalMode', this.productPage.taModal.customizeButton)

        break;
      case "advanced":
        /*
          Only use the existing the elements.
         */
        console.debug("designerMode advanced")
        if($fpdContainerSelection.length > 0) {
          this.productPage.$fpdNode = this.fetchFpdNode()
          console.debug(`Found FPD node for ${fpdContainer}`);
        } else {
          console.debug(`No node found for ${fpdContainer}`);
          console.info("FancyProductDesigner in advanced mode, but no selector found. No Fancy Prdocut Designer loading")
        }

        if($fpdCustomizeButtonSelection.length > 0) {
          this.productPage.$customizeButton = $fpdCustomizeButtonSelection
          console.debug(`Found customize button for ${customizeButton}`);

          this.productPage.updateFancyPluginOptions('modalMode', this.productPage.taModal.customizeButton)
        }
        break;
      default:
        console.error("no designer mode set.")
    }
  }
}
