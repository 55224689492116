import { get, set, includes, each } from 'lodash'
import $ from 'jquery'

/**
 *  The dynamic views operator is listing to changes in a form and updating the view accordingly.
 */
export default class DynamicViewsOperator {
  constructor(productPage) {
    this.productPage = productPage
    this.viewNavWidth = null
    this.viewNavHeight = null
    this.initCount = 0
    this.maxInitCount = 10
  } 

  get optionsManager() {
    return this.productPage.optionsManager
  }

  get taDyanmicViews() {
    return this.optionsManager.ta.dynamicViews || {}
  }

  get taViewNavInputsWidth() {
    return this.taDyanmicViews.viewNavInputsWidth || ".fpd-view-edit-size input[data-type='width']"
  }

  get taViewNavInputsHeight() {
    return this.taDyanmicViews.viewNavInputsHeight || ".fpd-view-edit-size input[data-type='height']"
  }

  get taExternalInputsWidth() {
    return this.taDyanmicViews.externalInputsWidth || ".fpd-view-size input[name='width']"
  }

  get taExternalInputsHeight() {
    return this.taDyanmicViews.externalInputsHeight || ".fpd-view-size input[name='height']"
  }

  initListener() {
    if (!this.optionsManager.isDynamicViewsEnabled()) {
      return
    }

    this.initViewNavInputs()
    if(this.viewNavWidth) {
      this.initExternalInputs()
      this.initHiddenFormFields()
      this.initChangeListeners()
    } else {
      this.initCount++
      if(this.initCount < this.maxInitCount) {
        setTimeout(this.initListener.bind(this), 1000)
      }
    }
  }

  initViewNavInputs() {
    this.viewNavWidth = document.querySelector(this.taViewNavInputsWidth)
    this.viewNavHeight = document.querySelector(this.taViewNavInputsHeight)
  }

  initExternalInputs() {
    this.externalWidth = document.querySelector(this.taExternalInputsWidth)
    this.externalHeight = document.querySelector(this.taExternalInputsHeight)
  }

  initHiddenFormFields() {
    const $form = this.productPage.$addToCartForm
    if($form.find('input[name="properties[_custom_width]"]').length > 0) {
      this.hiddenFormFieldWidth = $form.find('input[name="properties[_custom_width]"]')
    } else {
      this.hiddenFormFieldWidth = $(`<input type="hidden" name="properties[_custom_width]" />`)
      $form.append(this.hiddenFormFieldWidth)
    } 
    this.hiddenFormFieldWidth.val(this.externalWidth.value)

    if($form.find('input[name="properties[_custom_height]"]').length > 0) {
      this.hiddenFormFieldHeight = $form.find('input[name="properties[_custom_height]"]')
    } else {
      this.hiddenFormFieldHeight = $(`<input type="hidden" name="properties[_custom_height]" />`)
      $form.append(this.hiddenFormFieldHeight)
    } 
    this.hiddenFormFieldHeight.val(this.externalHeight.value)
  }

  initChangeListeners() {
    if(this.checkInputsElements()) {
      this.externalWidth.value = this.viewNavWidth.value
      this.externalWidth.addEventListener('change', this.updateViewSize.bind(this))
      this.externalWidth.addEventListener('change', this.updateHiddenFormFields.bind(this))
      this.externalHeight.value = this.viewNavHeight.value
      this.externalHeight.addEventListener('change', this.updateViewSize.bind(this))
      this.externalHeight.addEventListener('change', this.updateHiddenFormFields.bind(this))
    }
  }

  checkInputsElements() {
    return this.viewNavWidth && this.viewNavHeight && this.externalWidth && this.externalHeight
  }

  updateViewSize() {
    this.viewNavWidth.value = this.externalWidth.value
    this.viewNavHeight.value = this.externalHeight.value
    this.viewNavWidth.dispatchEvent(new Event('change'))
  }
  updateHiddenFormFields() {
    this.hiddenFormFieldWidth.val(this.externalWidth.value)
    this.hiddenFormFieldHeight.val(this.externalHeight.value)
  }
}