import CartModel from '../models/cart-model.js'
// import FontLoader from '../services/font-loader.js'
import { each, has, map, includes } from 'lodash'
import $ from 'jquery'

export default class GeneralPage {
  static isUrl(url = window.location.href) {
    return true
  }

  constructor(optionsManager) {
     this.optionsManager = optionsManager
     this.taJsCart = this.optionsManager.ta.cartJS
  }

  get fpdAdminUrl() {
    return this.optionsManager.fpdAdminUrl
  }
  get shopifyDomain() {
    return this.optionsManager.shopifyDomain
  }

  getAllFontsUrl() {
    let allFontsUrl = `${this.fpdAdminUrl}/api/hosted/all_fonts?shop_domain=${this.shopifyDomain}`
    if(this.optionsManager.isNoCache()) {
      allFontsUrl += `&no_cache=1`
    }
    return allFontsUrl
  }

  initListener() {
    $(document).on('fpd:cartChanged', () => {
      this.updateAllJsCartImages()
      this.updateAllJsCartLinks()
    })
  }

  executePreload() {
    this.loadAllFonts()
  }

  loadAllFonts() {
    fetch(this.getAllFontsUrl())
    .then(res => res.json())
    .then(response => {
      // FontLoader.load(response.fonts)
    })
  }

  updateAllJsCartImages() {
    const $jsCartContainer = $(this.taJsCart.cartElement)
    return CartModel.getCurrentCart(true).then(cartData => {
      return map(cartData.items, (item, index) => {
        if(!has(item.properties, '_fpd-url')) return 'non-fpd'

        const $item = $($jsCartContainer.find(this.taJsCart.cartItem)[index])
        return this.updateJsCartImage(item, $item)
      })
    })
  }

  updateJsCartImage(item, $item) {
    const imageUrl = item.properties['_fpd-url']
    const $image = $($item.find(this.taJsCart.cartImage))
    const $imageOrg = $image.clone()

    let resultValue = null
    if($image.is('img')) {
      $image.attr('src', imageUrl)
      $image.attr('srcset', imageUrl)
      resultValue = 'replaced'
    }
    if($image.is('a')) {
      $image.attr('href', imageUrl)
      if($image.css('background-image')) {
        $image.css('background-image', `url(${imageUrl})`)
      }
      resultValue = 'replaced'
    }
    if(this.optionsManager.hasCallback('cartJSImageFallback')) {
      this.optionsManager.invokeCallback('cartJSImageFallback', item, $item, $image, $imageOrg)
    }

    return resultValue || 'not-found'
  }

  updateAllJsCartLinks() {
    const $jsCartContainer = $(this.taJsCart.cartElement)
    return CartModel.getCurrentCart(true).then(cartData => {
      return map(cartData.items, (item, index) => {
        if(!has(item.properties, '_fpd-hash')) return 'non-fpd'

        const $item = $($jsCartContainer.find(this.taJsCart.cartItem)[index])
        return this.updateJsCartLinks(item, $item)
      })
    })
  }

  updateJsCartLinks(item, $item) {
    const fpdHash = item.properties['_fpd-hash']
    let editUrl = item.url

    editUrl += (item.url.includes("?") ? '&' : '?')
    editUrl += `_fpd-hash=${fpdHash}`

    const $productLinks = $item.find(`*[href*='${item.url}']`)

    map($productLinks, ($productLink) => {
      $productLink = $($productLink)
      $productLink.attr('href', editUrl)
    })

    return 'replaced'
  }

  debugCart() {
    fetch('/cart.js').then(response => response.json())
    .then(cart => {
      console.group("Cart Items Overview")
      each(cart.items, (item) => {
        console.debug(item.title, item.properties)
      })
      console.groupEnd("Cart Items Overview")
    })
  }
}
