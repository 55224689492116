import { isNil } from 'lodash'

import $ from 'jquery'

export default class CartModel {

  static _currentCart = null
  static _currentCartPromise = null

  static getCurrentCart(refresh = false) {
    if(CartModel.isCartLoaded() && !refresh) {
      return CartModel.getLoadedCart()
    }
    if(CartModel.isCartLoading()) {
      return CartModel.getLoadingPromise()
    }

    CartModel._currentCartPromise = fetch("/cart.js")
      .then(response => response.json())
      .then(cartData => {
        CartModel._currentCart = cartData
        CartModel._currentCartPromise = null

        return cartData
      })

    return CartModel._currentCartPromise
  }

  static getLoadedCart() {
    return Promise.resolve(CartModel._currentCart)
  }
  static isCartLoaded() {
    return !isNil(CartModel._currentCart)
  }

  static getLoadingPromise() {
    return CartModel._currentCartPromise
  }
  static isCartLoading() {
    return !isNil(CartModel._currentCartPromise)
  }

}
