export const formatMoney = (cents, format) => {
  cents = removeDots(cents)

  let value = '';
  const defaultFormat = '${{amount}}'
  const formatString = format || defaultFormat

  const valuePlaceholderRegex = /\{\{\s*(\w+)\s*\}\}/

  switch(formatString.match(valuePlaceholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',');
      break;
  }

  return formatString.replace(valuePlaceholderRegex, value);
}

const removeDots = (cents) => {
  if (typeof cents == 'string') {
    return cents.replace(/\./g,'')
  }
  return cents
}

const defaultOption = (value, defaultValue) => {
   return (typeof value == 'undefined' ? defaultValue : value);
}

const formatWithDelimiters = (number, precision, thousands, decimal) => {
  precision = defaultOption(precision, 2);
  thousands = defaultOption(thousands, ',');
  decimal   = defaultOption(decimal, '.');

  if (isNaN(number) || number == null) { return 0; }

  number = (number/100.0).toFixed(precision);

  var parts   = number.split('.'),
      dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
      cents   = parts[1] ? (decimal + parts[1]) : '';

  return dollars + cents;
}
