import { isNil, find, merge, each } from 'lodash'

import $ from 'jquery'

export default class ProductPageFormOperator {
  constructor(productPage) {
    this.productPage = productPage
  }

  get optionsManager() {
    return this.productPage.optionsManager
  }
  get wrappedFpd() {
    return this.productPage.fpdManager.wrappedInstance
  }

  getShopifyProduct() {
    return this.productPage.getShopifyProduct()
  }

  getCurrentShopifyVariant() {
    return this.productPage.getCurrentShopifyVariant()
  }
  isForceCustomProduct() {
    if(this.optionsManager.isAlwaysKeepProduct()) {
      return this.optionsManager.isAlwaysKeepProduct()
    }
    const formDataArray = this.productPage.$addToCartForm.serializeArray()
    const keepProductElement = find(formDataArray, ['name', '_keep_product'])
    return ! isNil(keepProductElement)
  }

  getAtcButtons() {
    return $(`${this.productPage.taProduct.addToCartForm} ${this.productPage.taProduct.addToCartFormSubmit}`)
  }

  buildProperties(lineItemCache, shopifyProduct) {
    let properties = {
      '_fpd-hash': lineItemCache.hash,
      '_fpd-url': lineItemCache.url,
      '_fpd-handle': shopifyProduct.handle
    }

    if(!this.optionsManager.isNoTextCount() && this.wrappedFpd.getCustomTextCount() > 0) {
      properties['Custom Texts'] = this.wrappedFpd.getCustomTextCount()
    }

    if(!this.optionsManager.isNoImageCount() && this.wrappedFpd.getCustomImageCount() > 0) {
      properties['Custom Images'] = this.wrappedFpd.getCustomImageCount()
    }

    if(this.optionsManager.isLinkPrintPdf()) {
      properties['_fpd_print_pdf'] = this.getPdfLink(lineItemCache)
    }
    if(this.optionsManager.isLinkAssets()) {
      properties = merge(properties, this.getAssetLinks())
    }

    if(this.optionsManager.hasCallback('cartTextPropertiesPost')) {
      properties = this.optionsManager.invokeCallback('cartTextPropertiesPost', properties, lineItemCache, shopifyProduct)
    }

    return properties
  }

  getPdfLink(lineItemCache) {
    return `${this.productPage.apiUrl}/api/fe/line_item_cache_print_pdf/${lineItemCache.hash}`
  }

  getAssetLinks() {
    let properties = {}
    const customElements = this.productPage.fpdManager.instance.getCustomElements('image')
    each(customElements, (element, i) => {
      properties[`_fpd-asset-${i}`] = element.element.source
    })

    return properties
  }

  getVariantId(lineItemCache) {
    return lineItemCache.custom_variant_id || lineItemCache.variant_id
  }

  replaceOrCreateInput(key, value) {
    let $existingInput, $newInput

    $existingInput = this.productPage.$addToCartForm.find(
      `input[name='${key}']`
    )

    if($existingInput.length > 0) {
      $existingInput.attr('value', value)
    } else {
      $newInput = $(`<input type="hidden" name="${key}" value="${value}" />`)
      this.productPage.$addToCartForm.append($newInput)
    }
  }
}
