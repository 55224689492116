import LicManager from '../services/lic-manager.js'
import AtcOperator from '../services/atc-operator.js'
import CbOperator from '../services/cb-operator.js'
import DesignerModeOperator from '../services/designer-mode-operator.js'
import ExternalElementsOperator from '../services/external-elements-operator.js'
import CustomPriceOperator from '../services/custom-price-operator.js'
import SaveButtonOperator from '../services/save-button-operator.js'
import DynamicViewsOperator from '../services/dynamic-views-operator.js'
import TextValueCacher from '../services/text-value-cacher.js'
import BulkOrderOperator from '../services/bulk-order-operator.js'
import ColorValueCacher from '../services/color-value-cacher.js'
import ProductModel from '../models/product-model.js'
import LanguageModel from '../models/language-model.js'
import Preview3dOperator from '../services/preview-3d-operator.js'

import customProductStyles from '!!raw-loader!../assets/css/fpd-fe-shopify-custom-product.css?raw'

import $ from "jquery"
import { isEmpty, isNil, find, map, get, set, sortBy, each, merge } from 'lodash'

export default class ProductPage {
  static urlRegex = /\/(products\/|products_preview|p\/).*/
  static isUrl(url = window.location.href) {
    const pathname = new URL(url).pathname
    return ProductPage.urlRegex.test(pathname)
  }

  constructor(optionsManager, fpdManager = null) {
    this._shopifyProduct = null
    this.licManager = null
    this.fpdManager = fpdManager
    this.optionsManager = optionsManager
    this.urlChangeInterval = null
    this.$fpdNode = null
    this.productModel = new ProductModel(this)
  }

  get fpdAdminUrl() {
    return this.optionsManager.fpdAdminUrl
  }

  get apiUrl() {
    return this.optionsManager.apiUrl
  }

  get appSubscription() {
    return this.optionsManager.subscription
  }

  get shopDomain() {
    return this.optionsManager.shopifyDomain
  }

  get taProduct() {
    return this.optionsManager.ta.product || {}
  }

  get taModal() {
    return this.optionsManager.ta.modal || {}
  }

  get fpdProductJSON() {
    if(this.productModel.fpdProductJSON) {
      return this.productModel.fpdProductJSON
    }
    return {}

    return get(this, 'productModel.fpdProductJSON', {})
  }

  getShopifyProduct() {
    if(!isNil(this._shopifyProduct)) {
      return this._shopifyProduct
    }

    if($("#ProductJson-FPD").length > 0) {
      this._shopifyProduct =  JSON.parse($("#ProductJson-FPD").html())
    }

    if(isNil(this._shopifyProduct)) {
      throw new Error('No Shopify product could be found.')
    }

    return this._shopifyProduct
  }

  getCurrentShopifyVariant(currentUrl = window.location.href) {
    const urlParams = new URLSearchParams(new URL(currentUrl).search)
    const variantId = urlParams.get('variant')

    if(!isNil(variantId)) {
      let variants = this.getShopifyProduct().variants
      this._currentShopifyVariant = find(variants, { 'id': parseInt(variantId) })
    }

    this._currentShopifyVariant ||= this.getShopifyProduct().variants[0]

    return this._currentShopifyVariant
  }

  getAtcButtons() {
    return this.atcOperator.getButtons()
  }

  getAtcBehavior() {
    const urlParams = this.optionsManager.getUrlParams()

    if(urlParams && urlParams.has('atc_b')) {
      return urlParams.get('atc_b')
    }

    if(!isNil(this.existingLic)) {
      return 'update'
    }

    return 'add'
  }

  updateTa(path, value) {
    this.optionsManager.updateTa(path, value)
  }

  updateShopOptions(path, value) {
    this.optionsManager.updateShopOptions(path, value)
  }

  updateFancyPluginOptions(path, value) {
    this.productModel.updatePluginOptions(path, value)
  }

  buildLicManager(FPDManager) {
    this.licManager = new LicManager(this.optionsManager, FPDManager)
  }

  initTextureSize() {
    fabric.textureSize = this.optionsManager.getTextureSize()
  }
  initPreview3d() {
    this.preeview3dOperator = new Preview3dOperator(this)
    this.preeview3dOperator.initReadyListener()
  }

  prepareDesignerMode() {
    this.designerModeOperator = new DesignerModeOperator(this)
    this.cbOperator = new CbOperator(this, this.fpdManager)

    this.designerModeOperator.prepare()
    if(this.designerModeOperator.isCustomize() && !isNil(this.existingLic)) {
      const that = this
      this.fpdManager.addProductCreateCallback(() => {
        setTimeout(() => {
          that.cbOperator.openAndCloseModal()
          that.cbOperator.replaceProductImage()
        }, 500)
      })
    }
  }

  initThemeAbstraction() {
    this.$addToCartForm ||= $(this.taProduct.addToCartForm)
    this.$addToCartFormSubmit ||= $(this.taProduct.addToCartFormSubmit)
    this.$fpdContainer ||= $(this.taProduct.fpdContainer)
    this.$saveCustomization ||= $(this.taProduct.saveCustomization)
    this.$customizeButton ||= $(this.taModal.customizeButton)
    this.$mainImage ||= $(this.taModal.mainImage)
  }

  initPageListeners() {
    this.initPageEnabled()
    this.initAddToCartListener()
    this.initUrlChangeListener()
    this.initVariantChangeListener()
    this.initExternalElementsListener()
    this.initCustomPriceListener()
    this.initSaveButtonListener()
    this.initElementModifyCallback()
    this.initDynamicViewsOperator()
    this.initMoneyFormat()
    this.initTextValueCacher()
    this.initColorValueCacher()
    this.invokeProductPageDoneCallback()
    this.initBulkOrderOperator()
  }

  initPageEnabled() {
    $('body').addClass('fpd-enabled')
  }

  initAddToCartListener() {
    this.atcOperator = new AtcOperator(this)
    this.atcOperator.initListener()
  }

  initDynamicViewsOperator() {
    this.dynamicViewOperator = new DynamicViewsOperator(this)
    this.dynamicViewOperator.initListener()
  }

  initCustomPriceListener() {
    const that = this
    that.customPriceOperator = CustomPriceOperator.buildForProductPage(that)
    $(document).on('fpd:url-changed', () => {
      that.customPriceOperator.basePrice = that.getCurrentShopifyVariant().price
      that.customPriceOperator.updateWithExtra(that.customPriceOperator.getCalculatedPrice())
      setTimeout(() => that.customPriceOperator.updateWithExtra(that.customPriceOperator.getCalculatedPrice()), 50)
      setTimeout(() => that.customPriceOperator.updateWithExtra(that.customPriceOperator.getCalculatedPrice()), 500)
      setTimeout(() => that.customPriceOperator.updateWithExtra(that.customPriceOperator.getCalculatedPrice()), 1500)
    })
    $(document).on('fpd:price:change', (event, fpdPrices) => {
      that.customPriceOperator.updateWithExtra(fpdPrices.elementPrice)
    })
  }

  initExternalElementsListener() {
    this.externalElementOperator = new ExternalElementsOperator(this)
    this.externalElementOperator.initListener()
  }

  initSaveButtonListener() {
    this.saveButtonOperator = new SaveButtonOperator(this)
    this.saveButtonOperator.initListener()
  }

  initTextValueCacher() {
    this.textValueCacher = new TextValueCacher(this)
    this.textValueCacher.initListener()
  }

  initColorValueCacher() {
    this.colorValueCacher = new ColorValueCacher(this)
    this.colorValueCacher.initListener()
  }

  initUrlChangeListener() {
    const that = this

    that.lastHref = window.location.href

    const urlChangeDetection = () => {
      if(that.lastHref != window.location.href) {
        that.lastHref = window.location.href
        $(document).trigger('fpd:url-changed', that.lastHref)
      }
    }

    if(that.urlChangeInterval === null) {
      that.urlChangeInterval = setInterval(urlChangeDetection, 100)
    }
  }

  initVariantChangeListener() {
    const that = this
    $(document).on('fpd:url-changed', (_e, url) => {
      const newProductModel = ProductModel.buildFromUrl(url, that)

      if(this.currentProductUrl == newProductModel.productUrl) {
        console.debug('Product url did not change.', this.currentProductUrl, newProductModel.productUrl)
        return
      }
      const oldFancyProductId = get(this.fpdProductJSON, 'plugin_options.productsJSON[0][0].product_id')
     
      if(this.optionsManager.isShowSpinnerOnVariantChange()) {
        this.fpdManager.instance.toggleSpinner(
          'show',
          LanguageModel.lookup('variantChangeSpinnerMessage', 'Please wait a moment.')
        )
      }
     
      newProductModel.load(true).then(() => {
        const newFancyProductId = get(newProductModel.fpdProductJSON, 'plugin_options.productsJSON[0][0].product_id')

        // Only if a different fancy product was assigned to the new product we load it.
        if(oldFancyProductId != newFancyProductId) {
          this.productModel = newProductModel
          this.afterNewProductLoaded()

          // load new fancy product designer
          const fdpProducts = this.fpdProductJSON.plugin_options.productsJSON[0]
          this.fpdManager.instance.loadProduct(fdpProducts, true, true)
        } else {
          this.fpdManager.instance.toggleSpinner(false)
        }
      })
    })
  }

  initMoneyFormat() {
    let fpdMoneyFormat = this.optionsManager.getFpdMoneyFormat()
    fpdMoneyFormat ||= this.fpdManager.inferMoneyFormat(this.optionsManager.moneyFormat)

    this.productModel.updatePluginOptions('priceFormat', fpdMoneyFormat)
  }

  initBulkOrderOperator() {
    this.bulkOrderOperator = new BulkOrderOperator(this)
    if(this.bulkOrderOperator.isBulkOrder()) {
      this.bulkOrderOperator.initListener()
    }
  }

  initElementModifyCallback() {
    if(this.optionsManager.hasCallback('elementModify')) {
      $(document).on('fpd:view:modified', (event, data) => {
        this.optionsManager.invokeCallback('elementModify', data.isCustomized)
      })
    }
  }

  checkToOpenDesigner() {
    if(this.optionsManager.isOpenDesignerWhenEdit() &&
        this.designerModeOperator.isCustomize() &&
        this.$customizeButton.length > 0 &&
        !isNil(this.existingLic)) {
      this.$customizeButton.click()
    }
    if(this.optionsManager.isOpenDesignerOnProduct() &&
        this.designerModeOperator.isCustomize() &&
        this.$customizeButton.length > 0) {
      this.$customizeButton.click()
    }
  }

  invokeProductPageDoneCallback() {
    if(this.optionsManager.hasCallback('productPageDone')) {
      this.optionsManager.invokeCallback('productPageDone')
    }
  }

  buildExistingLicUrl(currentUrl = window.location.href) {
    let searchParams = new URLSearchParams(new URL(currentUrl).search)
    let productHash = null

    if(searchParams.has(`_fpd-hash`)) {
      productHash = searchParams.get(`_fpd-hash`)
    } else {
      if(localStorage.getItem("_fpd-hash")) {
        productHash = localStorage.getItem("_fpd-hash")
      }
    }

    if(typeof FPD_EXT != 'undefined') {
      productHash = productHash != null ? productHash : FPD_EXT.productHash
    }

    if(productHash != null) {
      return `${this.apiUrl}/api/fe/line_item_cache/${productHash}.json?shop=${this.shopDomain}`
    }
    return null
  }

  /**
   * Loads fancy product json from fpd admin.
   *
   * Uses the product model.
   *
   * @return {Promise} Loading Promise
   */
  loadFancyProduct() {
    const productLoadingPromise = this.productModel.load()
    productLoadingPromise.then(() => {
      this.afterNewProductLoaded()
    }).catch(error => {
      // prevent unhandeld rejection
      return error
    })

    return productLoadingPromise
  }

  // TODO:
  // move to product model mostly
  afterNewProductLoaded() {
    window._fpdCornerControlsStyle = get(this.fpdProductJSON.misc, "corner_controls_style", "advanced")

    if(!isEmpty(this.fpdProductJSON.css)) {
      this.addCustomStyles(this.fpdProductJSON.css)
    }

    if(!isNil(this.fpdProductJSON.plugin_options.aiService)) {
      this.fpdProductJSON.plugin_options.aiService.serverURL = this.optionsManager.getAiServiceUrl()
    }
    // translate v1 options to v2
    if(this.fpdProductJSON.plugin_options.imageSizeTooltip) {
      this.fpdProductJSON.plugin_options.sizeTooltip = true
    }

  }

  loadExistingLic() {
    this.currentLicUrl = this.currentLicUrl || this.buildExistingLicUrl()
    if(this.currentLicUrl) {
      return fetch(this.currentLicUrl).
        then(response => response.json()).
        then(existingLic => {
          this.existingLic = existingLic
          return existingLic
        })
    }
    return Promise.resolve()
  }

  loadCustomProductStyles() {
    if(!document.getElementById('fpd-product-styles')) {
      this.addCustomStyles(customProductStyles, 'fpd-product-styles')
    }
  }

  /**
   * Adds the css string as new style node to head.
   *
   * @param {string} customCssString css styles
   */
  addCustomStyles(customCssString, styleId = 'fpd-styles') {
    console.log("Adding custom styles to head.", styleId)
    if(customCssString.length > 1) {
      let styleNode = document.createElement('style')
      styleNode.id = styleId
      styleNode.innerHTML = ''

      styleNode.type = 'text/css'
      styleNode.appendChild(document.createTextNode(customCssString.replace('[class^="fpd-element-toolbar"]', '.fpd-tools-nav')));

      if(document.getElementById(styleId)) {
        document.getElementById(styleId).remove()
      }
      const head = document.head || document.getElementsByTagName('head')[0]
      head.appendChild(styleNode)
    }
  }

  /**
   * Helps debugging the theme abstraction.
   *
   * Colors product ta elements and puts an overview in the console.
   *
   * @return {object} selectors and the elements they found
   */
  debugTa() {

    const taResultTable = {
      "addToCartForm": {
        Selector: this.taProduct.addToCartForm,
        Found: $(this.taProduct.addToCartForm).length,
        Status: $(this.taProduct.addToCartForm).length == 1 ? "good" : "bad"
      },
      "addToCartFormSubmit": {
        Selector: this.taProduct.addToCartFormSubmit,
        Found: $(`${this.taProduct.addToCartForm} ${this.taProduct.addToCartFormSubmit}`).length,
        Status: $(`${this.taProduct.addToCartForm} ${this.taProduct.addToCartFormSubmit}`).length == 1 ? "good" : "bad"
      },
      "fpdContainer": {
        Selector: this.taProduct.fpdContainer,
        Found: $(this.taProduct.fpdContainer).length,
        Status: $(this.taProduct.fpdContainer).length == 1 ? "good" : "bad"
      },
      "customizeButton": {
        Selector: this.taModal.customizeButton,
        Found: $(this.taModal.customizeButton).length,
        Status: $(this.taModal.customizeButton).length == 1 ? "good" : "bad"
      },
      "mainImage": {
        Selector: this.taModal.mainImage,
        Found: $(this.taModal.mainImage).length,
        Status: $(this.taModal.mainImage).length == 1 ? "good" : "bad"
      }
    }

    console.group("Theme Abstraction Overview: Product");
    console.table(taResultTable);
    console.groupEnd("Theme Abstraction Overview: Product");

    $(this.taProduct.addToCartForm).css({"backgroundColor":"rgba(10,0,0,0.2)", "border": "red 2px solid"})
    $(`${this.taProduct.addToCartForm} ${this.taProduct.addToCartFormSubmit}`).css({"backgroundColor":"rgba(0,150,0,0.2)", "border": "blue 2px solid"})
    $(this.taProduct.fpdContainer).css({"backgroundColor":"rgba(0,0,150,0.2)", "border": "green 2px solid"})
    $(this.taModal.customizeButton).css({"backgroundColor":"rgba(150,150,0,0.2)", "border": "yellow 2px solid"})
    $(this.taModal.mainImage).css({"backgroundColor":"rgba(150,0,150,0.2)", "border": "violet 2px solid"})

    return taResultTable
  }
}
