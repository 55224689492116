import CartPage from '../pages/cart-page.js'
import LanguageModel from '../models/language-model.js'

/**
 * Setup the designer on the cart page.
 *
 * It does:
 * - update images in the cart image
 * -
 *
 */
export default function initCartPage() {
  LanguageModel.init(FPD.lang)

  const cp = new CartPage(FPD.optionsManager)
  FPD.CartPage = cp
  FPD.func.updateCartImages = cp.updateImages.bind(cp);
  FPD.LanguageModel = LanguageModel
  
  cp.initPage()
}
