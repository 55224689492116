import $ from 'jquery'
import { each, isNil, includes } from 'lodash'
import BulkOrderForm from '../classes/BulkOrderForm'
import '../assets/css/fpd-bulk-order-form.scss'
/**
 * Bulk Order Operator
 * 
 * - Orders multiple variants of a customized product
 * - uses the atc operator to add the products to the cart
 * - shows the order form for the different variants
 */
export default class BulkOrderOperator {
  constructor(productPage) {
    this.productPage = productPage
    this.atcOperator = productPage.atcOperator
    this.bulkOrderContainer = null
    this.quantitySelector = productPage.taProduct.quantityContainer || ".product-form__quantity"
  }

  isBulkOrder() {
    this.bulkOrderContainer = document.querySelector('#fpd-bulk-order-container')
    return !isNil(this.bulkOrderContainer)
  }

  initListener() {
    this.initBulkOrderForm()
    this.hideNonBulkElements()
  }

  initBulkOrderForm() {
    this.bulkOrderForm = new BulkOrderForm(
      this.productPage.fpdManager.instance, 
      this.bulkOrderContainer, 
      this.productPage.getShopifyProduct(),
      this.createLicCallback.bind(this),
      this.afterAtcCallback.bind(this)
    )
    this.bulkOrderForm.initBulkOrderForm()
    $(document).on('fpd:price:change', () => this.bulkOrderForm.updatePrice())
  }

  hideNonBulkElements() {
    const $atcButtons = this.productPage.atcOperator.getAtcButtons()
    if($atcButtons) {
      $atcButtons.hide()
    }
    const quantityContainer = document.querySelector(this.quantitySelector)
    if(quantityContainer) {
      quantityContainer.style.display = "none"
    }
  }

  createLicCallback() {
    const shopifyProduct = this.productPage.getShopifyProduct()
    const variant = this.productPage.getCurrentShopifyVariant()
    return this.productPage.licManager.processLicCreation(shopifyProduct, variant, false)
      .then((lineItemCache) => {
        return {
          '_fpd-hash': lineItemCache.hash,
          '_fpd-url': lineItemCache.url,
          '_fpd-handle': shopifyProduct.handle
        }
      })
    
  }
  afterAtcCallback(data) {
    this.productPage.atcOperator.afterAtcBehavior(data)
  }
}