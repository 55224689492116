import GeneralPage from '../pages/general-page.js'


export default function initGeneralPage(notCartOrProduct = true) {
  const gp = new GeneralPage(FPD.optionsManager)
  gp.updateAllJsCartImages()
  gp.updateAllJsCartLinks()
  gp.initListener()
  if(notCartOrProduct) {
    gp.executePreload()
  }

  FPD.generalPage = gp

  FPD.func.updateCartJSImages = gp.updateAllJsCartImages.bind(gp);
}
